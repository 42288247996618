@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --white: #FBFBFF;
  --violet: #5603AD;
  --dark-blue: #151230;

  /* grads */
  --bg: linear-gradient(to right, #24243e, #231e55, #0f0c29);
  --light: linear-gradient(to right, #3F5EFB, #FC466B);
}

*{
  margin: 0;
}

h1{
  font-size: 2.5em;
  font-weight: 900;
  font-family: 'Bebas Neue';
}
h2{
  font-size: 2.1em;
  font-weight: 900;
  font-family: 'Bebas Neue';
}
p, a, label{
  font-size: 1.2em;
  font-weight: 600;
}

body{
  font-family: 'Roboto';
}
.home, .conditional_wrapper{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #0f0c29;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);  /* Chrome 10-25, Safari 5.1-6 */
  background: var(--bg);

  font-family: 'Roboto';
  color: white;
}

.header{
  width: 100%;
  background-color: var(--dark-blue);
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  h1{
    margin: 30px 0 10px 0;
  }

  a{
    color: transparent;
    background: linear-gradient(to right, #ff00ee, #37a547); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    -webkit-background-clip: text;
  }

  .center_header{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mint_pre_wrapper{
    position: absolute;
    right: 30px;
    top: 30px;
  }
}

.apps{
  display: flex;
  flex-direction: column;
}

.mint_box{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--violet);
}

.line{
  height: 1px;
  width: 80%;
  background-color: var(--white);
}

.input_box{
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 80%;

  margin-top: 30px;

  input{
    margin: 10px 0;
    width: 249px;
    height: 30px;
    background: var(--dark-blue);

    color: var(--white);
  
    border: none;
    border-radius  : 5px;
  }
}

.options{
  width: 80%;
  text-align: start;
}
.result{
  display: flex;
  flex-direction: column;
  background-color: white;
  font-family: 'Poppins';
  width: 70%;

  .result_btn{
    width: 100%;
    padding: 20px;
    text-align: left;
    margin: 0;
    font-family: 'Poppins';
    font-weight: 600;
    color: black;
    border: 1px solid rgb(223, 223, 223);
    background-color: white;
  }

  .name_container, .description_container, .properties_container{
    width: 468px;
    border: 1px solid white;
    padding: 20px;
    
    color: black;

    text-align: justify;

    p{
      font-size: 1em;
      font-weight: 300;
    }
  }

  .properties_container{
    width: 90%;
    display: grid;
    gap: 4vw;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  }
}

.prop_box{
  padding:10;
  border:1px solid rgb(21, 178, 229);
  border-radius: 5px;

  background-color: rgba(214, 242, 252, 0.8);

  text-align: center;
}

.metadata_sol{
  margin-top: 60px;

  text-align: start;
  color: white;

  background-color: #0f0c29;
  padding: 30px;
  border-radius: 10px;

  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);

  p{
    font-weight:300;
  }

  .metadata_sol_list{
    text-align: left;
    margin-left: 10%;
    font-size: 0.9em;
  }

  a{
    color: white;
  }
}

.result_input{
  width: 410px;
  height: 300px;
  // background: linear-gradient(to left, #fb00ff6c 50%, #00dade);

  border-radius: 10px;

  // font-family: 'Roboto';
  font-size: 1.1em;
  font-weight: 400;
}
.result .button{
  align-self: flex-end;
}

.checksum_result{
  display: flex;
  flex-direction: column;
}
.additional_option{
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  label{
    margin-right: 10px;
   }
}

textarea{
  width: 410px;
  height: 300px;

  border-radius: 10px;

  font-size: 1.1em;
  font-weight: 400;
}

.button{
  width: max-content;
  padding: 10px 20px;
  margin: 30px 0;

  cursor: pointer;

  color: white;
  background-color: var(--dark-blue);
  border: 1px solid var(--violet);
  border-radius: 5px;

  font-family: 'Roboto';
  font-size: 1.2em;
  font-weight: 800;
  transition: all ease-in-out 200ms;
}

.button:hover{
  background-color: var(--violet);
}

.notes{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  margin: 30px 0 60px 0;

}
.notes_container{
  text-align: start;
  color: white;
  width: 80%;

  background-color: #0f0c29;
  padding: 30px;
  border-radius: 10px;

  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);

  p{
    font-weight:300;
  }
}

.forbiden{
  width: auto;
  height: auto;
  border: 1px solid black;
  background-color: var(--dark-blue);
}

.menu{
  position: absolute;
  right: 30px;
  height: auto;

  .menu_items{
    display: flex;
    flex-direction: column;
    height: max-content;
    padding: 0;
  }
  .button{
    margin: 5px 0;
  }

  .menu_item{
    list-style: none;
    height: auto;
    margin: 5px 0;
    font-size: 0.9em;
  }
}

.separator{
  height: 90px;
  width: 100%;
  background-color: black;
}

.pre_description{
  margin: 60px auto;
  width: 80%;
  padding: 20px;

  background-color:#302b63;
  border-radius: 10px;
  color: white;

  h4{
    font-size: 1.4em;
  }
  p{
    font-size: 1em;
  }
}

.hamburger{
  display: none;
}
// Mobile

@media screen and (max-width: 750px){
  .header{
    justify-content: left;

    .center_header{
      height: 100%;
      align-items: flex-start;
      margin-left: 10px;

      h1{
        margin-top: 10px;
        font-size: 1.8em;
      }
      p{
        font-size: 0.8em;
      }
    }
  }
  

  .menu{

    .menu_items{
    position: relative;
    margin: 0 0 3px 0;
    top: 20px;
    right: -30px;
    width: auto;

    border-radius: 5px;
    background-color: rgb(238, 130, 195);

    transform: scale(0);
    transition: all ease-in-out;
    }
    .active{
      transform: scale(1);
    }

    .menu_item{
      background-color: transparent;
      font-size: 0.8em;
    }

    .button{
      height: auto;
      width: auto;
      padding: 0;
      margin: 0;

      background-color: transparent;
      border: none;
    }

    .hamburger{
      display: flex;
      position: absolute;
      top: -10px;
      right: -20px;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 30px;
      height: 25px;

      border: none;

      background-color: var(--dark-blue);

      .hamb_line{
        width: 35px;
        height: 3px;
        background-color: white;
      }
    }
  }
  
}